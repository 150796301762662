@import './constants.css';
@import './animations.css';

/* Файлы с CSS компонентов подключаются здесь неслучайно. Пока не удалось делать импорты через JS так,
 * чтобы в каждом файле были доступны CSS переменные без их дублирования.
 */

/* Typography */
@import '../components/Typography/Title/Title.css';
@import '../components/Typography/Headline/Headline.css';
@import '../components/Typography/Subhead/Subhead.css';
@import '../components/Typography/Caption/Caption.css';
@import '../components/Typography/Text/Text.css';

/* Service */
@import '../components/Tappable/Tappable.css';
@import '../components/FixedLayout/FixedLayout.css';

/* Primitives */
@import '../components/Button/Button.css';
@import '../components/Removable/Removable.css';

/* Layout */
@import '../components/Root/Root.css';
@import '../components/View/View.css';
@import '../components/Panel/Panel.css';
@import '../components/PanelHeader/PanelHeader.css';
@import '../components/PanelHeaderButton/PanelHeaderButton.css';
@import '../components/PanelHeaderBack/PanelHeaderBack.css';
@import '../components/PanelHeaderContent/PanelHeaderContent.css';
@import '../components/PanelHeaderContext/PanelHeaderContext.css';
@import '../components/PopoutRoot/PopoutRoot.css';
@import '../components/SplitLayout/SplitLayout.css';
@import '../components/SplitCol/SplitCol.css';
@import '../components/Epic/Epic.css';
@import '../components/Tabbar/Tabbar.css';
@import '../components/TabbarItem/TabbarItem.css';
@import '../components/HorizontalScroll/HorizontalScroll.css';
@import '../components/HorizontalScroll/HorizontalScrollArrow.css';

/* Popouts */
@import '../components/PopoutWrapper/PopoutWrapper.css';
@import '../components/ActionSheet/ActionSheet.css';
@import '../components/ActionSheetItem/ActionSheetItem.css';
@import '../components/Alert/Alert.css';
@import '../components/ScreenSpinner/ScreenSpinner.css';
@import '../components/Snackbar/Snackbar.css';

/* Modals */
@import '../components/ModalRoot/ModalRoot.css';
@import '../components/ModalCard/ModalCard.css';
@import '../components/ModalPage/ModalPage.css';
@import '../components/ModalDismissButton/ModalDismissButton.css';
@import '../components/ModalPageHeader/ModalPageHeader.css';

/* Blocks */
@import '../components/Badge/Badge.css';
@import '../components/IconButton/IconButton.css';
@import '../components/Card/Card.css';
@import '../components/CardGrid/CardGrid.css';
@import '../components/CardScroll/CardScroll.css';
@import '../components/ContentCard/ContentCard.css';
@import '../components/Div/Div.css';
@import '../components/Link/Link.css';
@import '../components/Header/Header.css';
@import '../components/Group/Group.css';
@import '../components/Gradient/Gradient.css';
@import '../components/SimpleCell/SimpleCell.css';
@import '../components/Cell/Cell.css';
@import '../components/CellButton/CellButton.css';
@import '../components/RichCell/RichCell.css';
@import '../components/HorizontalCell/HorizontalCell.css';
@import '../components/List/List.css';
@import '../components/Footer/Footer.css';
@import '../components/Spinner/Spinner.css';
@import '../components/Switch/Switch.css';
@import '../components/InfoRow/InfoRow.css';
@import '../components/Avatar/Avatar.css';
@import '../components/Gallery/Gallery.css';
@import '../components/Progress/Progress.css';
@import '../components/Search/Search.css';
@import '../components/Tabs/Tabs.css';
@import '../components/TabsItem/TabsItem.css';
@import '../components/Tooltip/Tooltip.css';
@import '../components/PullToRefresh/PullToRefresh.css';
@import '../components/Counter/Counter.css';
@import '../components/UsersStack/UsersStack.css';
@import '../components/Separator/Separator.css';
@import '../components/Spacing/Spacing.css';
@import '../components/Placeholder/Placeholder.css';
@import '../components/Banner/Banner.css';
@import '../components/MiniInfoCell/MiniInfoCell.css';
@import '../components/WriteBar/WriteBar.css';
@import '../components/WriteBarIcon/WriteBarIcon.css';
@import '../components/SubnavigationBar/SubnavigationBar.css';
@import '../components/SubnavigationButton/SubnavigationButton.css';

/* Forms */
@import '../components/FormLayout/FormLayout.css';
@import '../components/FormItem/FormItem.css';
@import '../components/FormLayoutGroup/FormLayoutGroup.css';
@import '../components/FormStatus/FormStatus.css';
@import '../components/Slider/Slider.css';
@import '../components/FormField/FormField.css';
@import '../components/Radio/Radio.css';
@import '../components/Checkbox/Checkbox.css';
@import '../components/Input/Input.css';
@import '../components/ChipsInput/ChipsInput.css';
@import '../components/Chip/Chip.css';
@import '../components/Select/Select.css';
@import '../components/Textarea/Textarea.css';
@import '../components/File/File.css';
@import '../components/CustomScrollView/CustomScrollView.css';
@import '../components/CustomSelect/CustomSelect.css';
@import '../components/CustomSelectOption/CustomSelectOption.css';
@import '../components/DatePicker/DatePicker.css';
@import '../components/SliderSwitch/SliderSwitch.css';

/* Advertisement */
@import '../components/PromoBanner/PromoBanner.css';

.vkui > body {
  overflow-x: hidden;
}

.vkui,
.vkui > body,
.vkui__root {
  margin: 0;
  padding: 0;
  height: 100%;
}

.vkui,
.vkui > body,
.vkui__root,
.vkui__portal-root {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  font-family: var(--font-common);
  color: var(--text_primary);
}

.vkui__root--embedded {
  transform: translate3d(0, 0, 0);
  overflow-x: hidden;
}

.AppRoot {
  height: 100%;
}

.vkui__root--embedded .AppRoot {
  overflow: auto;
}

.vkui--sizeX-regular {
  background: var(--background_page);
}

.AppRoot--no-mouse {
  user-select: none;
}

.vkui__root input,
.vkui__root textarea,
.vkui__root select,
.vkui__root button {
  font-family: inherit;
}

.vkui__root a:focus {
  outline: none;
}
