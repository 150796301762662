.SimpleCell {
  display: flex;
  align-items: center;
  min-height: 48px;
  white-space: nowrap;
  text-decoration: none;
  color: var(--text_primary);
}

.SimpleCell--mult {
  white-space: normal;
}

.SimpleCell--mult .SimpleCell__description,
.SimpleCell--mult .SimpleCell__children {
  text-overflow: initial;
}

.SimpleCell > .Avatar,
.SimpleCell > .Icon {
  flex-shrink: 0;
}

.SimpleCell > .Icon {
  color: var(--accent);
}

.SimpleCell__main {
  max-width: 100%;
  flex-grow: 1;
  min-width: 0;
  flex-shrink: initial;
  overflow: hidden;
}

.SimpleCell > .Icon,
.SimpleCell > .Avatar {
  padding-right: 12px;
}

.SimpleCell > .Icon--28 {
  padding-right: 16px;
}

.SimpleCell > .Icon,
.SimpleCell > .Avatar--sz-32,
.SimpleCell > .Avatar--sz-28 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.SimpleCell > .Avatar--sz-40 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.SimpleCell > .Avatar--sz-48,
.SimpleCell > .Avatar--sz-72 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.SimpleCell__description {
  color: var(--text_secondary);
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 3px;
}

.SimpleCell > .Avatar--sz-32 ~ .SimpleCell__main .SimpleCell__description,
.SimpleCell > .Avatar--sz-28 ~ .SimpleCell__main .SimpleCell__description {
  margin-top: 2px;
}

.SimpleCell:not(.SimpleCell--mult) .SimpleCell__content {
  display: flex;
  flex-flow: row nowrap;
  align-content: flex-start;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}

.SimpleCell__children {
  color: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
}

.SimpleCell__badge {
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 4px;
  color: var(--blue_200);
}

.SimpleCell__badge .Badge {
  margin-top: 2px;
  margin-left: 3px;
}

.SimpleCell--mult .SimpleCell__badge {
  vertical-align: top;
}

.SimpleCell--mult .SimpleCell__badge .Icon {
  transform: translateY(2px);
}

.SimpleCell--mult .SimpleCell__badge .Badge {
  margin-top: 8px;
}

.SimpleCell__indicator {
  color: var(--text_secondary);
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 4px;
}

.SimpleCell__after {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  color: var(--accent);
}

.SimpleCell__after > .Icon {
  padding-left: 8px;
}

.SimpleCell__after > .Icon:last-child {
  padding-right: 2px;
}

.SimpleCell .Switch {
  padding-left: 10px;
}

.SimpleCell__after .IconButton .Icon--28,
.SimpleCell__after .IconButton .Icon--28 ~ * {
  margin-right: -8px;
}

.SimpleCell__after .IconButton .Icon--24,
.SimpleCell__after .IconButton .Icon--24 ~ * {
  margin-right: -10px;
}

.SimpleCell__after .IconButton .Icon--16,
.SimpleCell__after .IconButton .Icon--16 ~ * {
  margin-right: -14px;
}

.SimpleCell__after .IconButton .Icon--w-8,
.SimpleCell__after .IconButton .Icon--w-8 ~ * {
  margin-right: -12px;
}

/**
 * iOS
 */

.SimpleCell--ios {
  padding-left: 12px;
  padding-right: 12px;
}

.SimpleCell--ios .SimpleCell__main,
.SimpleCell--ios .SimpleCell__indicator {
  padding-top: 9px;
  padding-bottom: 11px;
}

.SimpleCell--ios > .Icon--28,
.SimpleCell--ios > .Avatar--sz-32,
.SimpleCell--ios > .Avatar--sz-28 {
  padding-left: 4px;
}

.SimpleCell--ios .SimpleCell__after .Icon--chevron_24 {
  color: var(--icon_tertiary);
  padding-right: 4px;
  padding-left: 12px;
}

.SimpleCell--ios .SimpleCell__after .IconButton .Icon--16,
.SimpleCell--ios .SimpleCell__after .IconButton .Icon--16 ~ * {
  margin-right: -12px;
}

.SimpleCell--ios.SimpleCell--sizeY-compact .SimpleCell__after .IconButton .Icon--16,
.SimpleCell--ios.SimpleCell--sizeY-compact .SimpleCell__after .IconButton .Icon--16 ~ * {
  margin-right: -14px;
}

.SimpleCell--ios.SimpleCell--sizeY-regular > .Avatar:not(.Avatar--sz-32) ~ .SimpleCell__main .SimpleCell__children,
.SimpleCell--ios.SimpleCell--sizeY-regular > .Avatar:not(.Avatar--sz-32) ~ .SimpleCell__indicator {
  font-size: 16px;
  line-height: 20px;
}

/**
 * Android & VKCOM
 */

.SimpleCell--android,
.SimpleCell--vkcom {
  padding-left: 16px;
  padding-right: 16px;
}

.SimpleCell--android .SimpleCell__main,
.SimpleCell--android .SimpleCell__indicator,
.SimpleCell--vkcom .SimpleCell__main,
.SimpleCell--vkcom .SimpleCell__indicator {
  padding-top: 11px;
  padding-bottom: 10px;
}

/**
 * sizeY === 'compact'
 */

.SimpleCell--sizeY-compact {
  min-height: 44px;
}

.SimpleCell--sizeY-compact .SimpleCell__main,
.SimpleCell--sizeY-compact .SimpleCell__indicator {
  padding-top: 10px;
  padding-bottom: 10px;
}

.SimpleCell--sizeY-compact > .Avatar--sz-40 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.SimpleCell--sizeY-compact > .Avatar--sz-48 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.SimpleCell--sizeY-compact > .Icon,
.SimpleCell--sizeY-compact > .Avatar--sz-32,
.SimpleCell--sizeY-compact > .Avatar--sz-28 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.SimpleCell--sizeY-compact .SimpleCell__description,
.SimpleCell--sizeY-compact > .Avatar--sz-32 ~ .SimpleCell__main .SimpleCell__description,
.SimpleCell--sizeY-compact > .Avatar--sz-28 ~ .SimpleCell__main .SimpleCell__description {
  margin-top: 1px;
}

.SimpleCell--sizeY-compact .SimpleCell__after .IconButton .Icon--28,
.SimpleCell--sizeY-compact .SimpleCell__after .IconButton .Icon--28 ~ * {
  margin-right: -6px;
}

.SimpleCell--sizeY-compact .SimpleCell__after .IconButton .Icon--24,
.SimpleCell--sizeY-compact .SimpleCell__after .IconButton .Icon--24 ~ * {
  margin-right: -8px;
}

.SimpleCell--sizeY-compact .SimpleCell__after .IconButton .Icon--16,
.SimpleCell--sizeY-compact .SimpleCell__after .IconButton .Icon--16 ~ * {
  margin-right: -12px;
}

.SimpleCell--sizeY-compact .SimpleCell__after .IconButton .Icon--w-8,
.SimpleCell--sizeY-compact .SimpleCell__after .IconButton .Icon--w-8 ~ * {
  margin-right: -10px;
}

.SimpleCell--sizeY-compact .SimpleCell__badge .Badge {
  transform: translateY(.5px);
}
