.ModalCard {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  z-index: 1;
}

.ModalCard__in {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 8px;
  transform: translateY(100%);
}

.ModalCard__container {
  box-sizing: border-box;
  position: relative;
  background: var(--modal_card_background);
  border-radius: 18px;
  padding: 16px;
  margin-bottom: var(--safe-area-inset-bottom);
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: initial;
}

.ModalCard__dismiss {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 48px;
  height: 48px;
  color: var(--placeholder_icon_foreground_primary);
  justify-content: center;
}

.ModalCard__header,
.ModalCard__subheader {
  text-align: center;
  margin: 0;
  padding: 0 8px;
}

.ModalCard__header {
  color: var(--text_primary);
}

.ModalCard__subheader {
  color: var(--text_secondary);
}

.ModalCard__header + .ModalCard__subheader {
  margin-top: 8px;
}

.ModalCard__header + .FormField,
.ModalCard__subheader + .FormField {
  margin-top: 16px;
}

.ModalCard .UsersStack {
  margin-top: 20px;
}

.ModalCard__icon {
  margin: 8px auto 16px;
  color: var(--accent);
}

.ModalCard__actions {
  display: flex;
  margin-left: -6px;
  margin-right: -6px;
  margin-top: 16px;
}

.ModalCard .UsersStack + .ModalCard__actions {
  margin-top: 32px;
}

.ModalCard__header + .ModalCard__actions,
.ModalCard__subheader + .ModalCard__actions {
  margin-top: 32px;
}

.ModalCard__actions .Button {
  flex: 1;
  margin-left: 6px;
  margin-right: 6px;
}

.ModalCard__actions--v {
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
}

.ModalCard__actions--v .Button {
  margin-left: 0;
  margin-right: 0;
}

.ModalCard__actions--v .Button + .Button {
  margin-top: 12px;
}

/**
 * iOS
 */

.ModalCard--ios .ModalCard__in {
  transition: transform 340ms var(--ios-easing);
  max-width: 414px;
}

.ModalCard--ios .ModalCard__header:first-child {
  padding-left: 36px;
  padding-right: 36px;
}

/**
 * Android + vkcom
 */

 .ModalCard--android .ModalCard__in {
  max-width: 440px;
}

.ModalCard--vkcom .ModalCard__in {
  max-width: 400px;
}

.ModalCard--android .ModalCard__in,
.ModalCard--vkcom .ModalCard__in {
  transition: transform 340ms var(--android-easing);
}

.ModalCard--android .ModalCard__container,
.ModalCard--vkcom .ModalCard__container {
  border-radius: 12px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, .16), 0 0 4px 0 rgba(0, 0, 0, .08);
}

/**
 * Desktop
 */

.ModalCard--desktop {
  align-items: center;
}

.ModalCard--desktop .ModalCard__in {
  transform: unset;
  opacity: 0;
  transition: opacity 340ms var(--android-easing);
}

.ModalCard--desktop .ModalCard__container {
  border-radius: 8px;
  box-shadow:
    0 16px 16px rgba(0, 0, 0, .16),
    0 0 8px rgba(0, 0, 0, .12);
}

/**
 * Common
 */

.ModalRoot--touched .ModalCard__in {
  transition: none;
}
