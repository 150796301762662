.Group {
  color: var(--text_primary);
}

.Group__inner {
  padding-top: 8px;
  padding-bottom: 8px;
}

.Group--sizeX-regular > .Group__inner {
  padding: 8px;
}

.Group--card > .Group__inner {
  background: var(--background_content);
  border-radius: 8px;
  box-shadow: inset 0 0 0 var(--thin-border) var(--input_border);
}

.Group--plain:not(:first-of-type) .Group__inner > .Header:first-child {
  margin-top: -16px;
}

.Group--plain .Group__inner > .Tabs--default:first-child {
  margin-top: -8px;
}

.Group--card > .Group__inner > .Header:not(.Header--mode-tertiary):first-child,
.Group--card > .Group__inner > .Tabs--default:first-child {
  margin-top: -4px;
}

.Group--plain > .Group__separator {
  padding-top: 8px;
  padding-bottom: 8px;
}

.Group--sizeX-regular.Group--plain > .Group__separator {
  padding: 8px;
}

.Group:last-of-type > .Group__separator {
  display: none;
}

.Group:last-of-type > .Group__separator--force,
.Group--sizeX-regular.Group--card:last-of-type > .Group__separator {
  display: block;
}

.Group__description {
  color: var(--text_secondary);
}

.Group .Group > .Group__inner {
  padding-left: 0;
  padding-right: 0;
}

.Group .Group > .Group__separator {
  padding-left: 0;
  padding-right: 0;
}

.Group .Group:first-of-type > .Group__inner {
  padding-top: 0;
}

.Group .Group:last-of-type > .Group__inner {
  padding-bottom: 0;
}

/* iOS */

.Group--ios .Group__description {
  padding: 4px 12px 16px;
}

/* Android & vkcom */

.Group--android .Group__description,
.Group--vkcom .Group__description {
  padding: 4px 16px 16px;
}

/* vkcom */

.PanelHeader--vkcom ~ .Group--card:first-of-type,
.PanelHeader--vkcom + * .Group--card:first-of-type {
  position: relative;
  top: -1px;
}

.PanelHeader--vkcom ~ .Group--card:first-of-type .Group__inner,
.PanelHeader--vkcom + * .Group--card:first-of-type .Group__inner {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
