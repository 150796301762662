.SliderSwitch {
  display: flex;
  position: relative;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: var(--field_background);
  transition: all 100ms ease-out;
  outline: none;
}

.SliderSwitch__button {
  width: 50%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  background: transparent;
  color: var(--text_subhead);
  transition: color 100ms ease-out;
  outline: none;
  user-select: none;
  z-index: 1;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.SliderSwitch__button--focus {
  box-shadow: inset 0 0 0 1px var(--accent);
}

.SliderSwitch__button--ios {
  border-radius: 10px;
}

.SliderSwitch__button--android,
.SliderSwitch__button--vkcom {
  border-radius: 8px;
}

.SliderSwitch__button--hover {
  color: var(--text_tertiary);
}

.SliderSwitch__border {
  position: absolute;
  left: 50%;
  top: 8px;
  width: 1px;
  height: 28px;
  opacity: .5;
  background-color: var(--toolbar_attach_background_from);
}

.SliderSwitch__button--active {
  color: var(--text_primary);
  transition: transform 150ms ease-in;
}

.SliderSwitch__button--activeHover {
  color: var(--header_alternate_tab_active_text);
}

.SliderSwitch__slider {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(50% - 4px);
  height: 40px;
  margin: 2px;
  border-radius: 6px;
  transition: transform 150ms;
}

.SliderSwitch--firstActive {
  margin-left: 2px;
  background-color: var(--background_content);
}

.SliderSwitch--secondActive {
  transform: translateX(calc(100% + 4px));
  background-color: var(--background_content);
}
