.InfoRow {
  user-select: text;
  text-overflow: ellipsis;
  overflow: hidden;
}

.InfoRow__header {
  margin-bottom: 2px;
  color: var(--text_subhead);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
