.Placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Placeholder--stretched {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  padding-top: inherit;
  padding-bottom: inherit;
}

.Placeholder__in {
  text-align: center;
  box-sizing: border-box;
  padding: 48px 32px;
}

.Placeholder__icon {
  margin-bottom: 12px;
  display: inline-block;
  vertical-align: top;
  color: var(--placeholder_icon_foreground_secondary);
}

.Placeholder__header {
  color: var(--text_primary);
}

.Placeholder__text {
  color: var(--text_placeholder);
}

.Placeholder__header + .Placeholder__text {
  margin-top: 8px;
}

.Placeholder__action:not(:first-child) {
  margin-top: 24px;
}

.Placeholder__action .Button--lvl-tertiary {
  top: -8px;
}
