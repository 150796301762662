.Chip {
  display: inline-block;
  border: 1px solid var(--image_border);
  background: var(--background_content);
  border-radius: 4px;
  height: 28px;
  box-sizing: border-box;
}

.Chip__in {
  display: flex;
  height: 100%;
  padding: 0 8px;
  align-items: center;
}

.Chip__content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--text_primary);
  display: inline-block;
}

.Chip__remove {
  margin-left: 4px;
  margin-right: -2px;
  cursor: pointer;
}

.Chip__before {
  margin-right: 6px;
}

.Chip__after {
  margin-left: 6px;
}
