.Avatar {
  box-sizing: border-box;
  color: var(--placeholder_icon_background);
}

.Avatar__in {
  position: relative;
}

.Avatar__img {
  background: currentColor;
  border: none;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  border-radius: inherit;
}

.Avatar__shadow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 var(--thin-border) var(--image_border);
  pointer-events: none;
  z-index: 2;
  border-radius: inherit;
}

.Avatar__children {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  color: var(--icon_secondary);
}
