.Banner {
  color: var(--text_primary);
  margin: 12px 0;
}

.Banner__in {
  display: flex;
  position: relative;
  overflow: hidden;
}

.Banner__in::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  display: block;
  border: var(--thin-border) solid var(--image_border);
  z-index: 2;
  box-sizing: border-box;
  border-radius: inherit;
  pointer-events: none;
}

.Banner__before {
  margin-right: 12px;
}

.Banner__content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  min-width: 0;
}

.Banner__subheader {
  color: var(--text_subhead);
}

.Banner__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.Banner__bg > * {
  width: 100%;
  height: 100%;
}

.Banner__expand {
  align-self: center;
  color: var(--icon_tertiary);
  margin-left: 12px;
}

.Banner__dismiss {
  width: 28px;
}

.Banner__dismissIcon {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--icon_tertiary);
}

.Banner__dismissIcon:active {
  opacity: .4;
}

.Banner__actions {
  margin-bottom: -6px;
}

.Banner__actions > .Button {
  margin-top: 12px;
  margin-right: 16px;
  margin-bottom: 6px;
}

.Banner__actions > .Button--lvl-tertiary {
  margin: 4px 16px 2px -16px;
}

.Banner__actions > .Button--lvl-tertiary:first-child {
  margin-top: 4px;
}

.Banner__actions > .Button:not(.Button--lvl-tertiary) + .Button:not(.Button--lvl-tertiary) {
  margin-top: 0;
}

.Banner__actions > .Button:not(.Button--lvl-tertiary) + .Button--lvl-tertiary {
  margin-top: 2px;
  margin-left: -16px;
}

.Banner__actions > .Button--lvl-tertiary + .Button--lvl-tertiary {
  margin-top: 2px;
}

/**
 * Size "s"
 */

.Banner--sz-s .Banner__subheader:not(:first-child),
.Banner--sz-s .Banner__text:not(:first-child) {
  margin-top: 2px;
}

/**
 * Size "m"
 */

.Banner--sz-m .Banner__subheader:not(:first-child),
.Banner--sz-m .Banner__text:not(:first-child) {
  margin-top: 4px;
}

/**
 * Mode "tint"
 */

.Banner--md-tint .Banner__in {
  background-color: var(--content_tint_background);
}

.Banner--md-tint .Banner__in {
  padding: 12px;
}

/**
 * Mode "tint" and size "s"
 */

.Banner--md-tint.Banner--sz-s .Banner__before {
  margin-right: 8px;
}

.Banner--md-tint.Banner--sz-s .Banner__content {
  padding-left: 4px;
}

/**
 * Mode "tint" and size "m"
 */

.Banner--md-tint.Banner--sz-m .Banner__in {
  padding: 16px;
}

/**
 * Mode "image"
 */

.Banner--md-image .Banner__in {
  background-color: var(--content_tint_background);
  padding: 12px 16px;
}

.Banner--md-image .Banner__before,
.Banner--md-image .Banner__content {
  position: relative;
  z-index: 2;
}

.Banner--inverted {
  color: var(--white);
}

.Banner--inverted .Banner__subheader {
  color: var(--white);
  opacity: .72;
}

/**
 * Mode "tint" and size "m"
 */

.Banner--md-image.Banner--sz-m .Banner__in {
  padding: 16px;
}

/**
 * iOS
 */

.Banner--ios {
  padding: 0 12px;
}

.Banner--ios .Banner__in {
  border-radius: 10px;
}

/**
 * Android & VKCOM
 */

.Banner--android,
.Banner--vkcom {
  padding: 0 16px;
}

.Banner--android .Banner__in,
.Banner--vkcom .Banner__in {
  border-radius: 8px;
}

.Banner--android .Banner__dismissIcon,
.Banner--vkcom .Banner__dismissIcon {
  color: var(--icon_secondary);
}

.Banner--android.Banner--md-image .Banner__dismissIcon,
.Banner--vkcom.Banner--md-image .Banner__dismissIcon {
  color: var(--white);
}
