.CustomSelect {
  width: 100%;
  position: relative;
  cursor: pointer;
  display: block;
}

.CustomSelect__control {
  display: none;
}

.CustomSelect__options {
  width: 100%;
  overflow: hidden;
  position: absolute;
  background-color: var(--modal_card_background);
  box-sizing: border-box;
  border: solid 1px var(--field_border);
  border-top: 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .06), 0 0 2px rgba(0, 0, 0, .03);
  border-radius: 0 0 8px 8px;
  max-height: 160px;
  z-index: 4;
}

.CustomSelect__options--popupDirectionTop {
  bottom: 100%;
  border-radius: 8px 8px 0 0;
  border-top: unset;
  border-bottom: 0;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, .06), 0 0 2px rgba(0, 0, 0, .03);
}

.CustomSelect__options .CustomScrollView__box {
  max-height: 160px;
}

.CustomSelectOption__selectedIcon {
  position: absolute;
  top: 15px;
  right: 12px;
}

.CustomSelect__options--sizeY-compact .CustomSelectOption {
  height: 36px;
  padding: 10px 9px;
}

.CustomSelect__options--sizeY-compact .CustomSelectOption__selectedIcon {
  top: 11px;
}

.CustomSelect__open.Select--mimicry:focus .FormField__border {
  border-color: var(--field_border);
}

.CustomSelect__open:not(.CustomSelect__open--popupDirectionTop) .FormField__border {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.CustomSelect__open.CustomSelect__open--popupDirectionTop .FormField__border {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
