.Select {
  position: relative;
  box-sizing: border-box;
}

.Select--mimicry:focus {
  outline: none;
}

.Select__el {
  display: block;
  position: absolute;
  appearance: none;
  outline: none;
  border: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 3;
  font-size: 16px;
}

.Select__title {
  display: block;
}

.Select__container {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 11px 11px 12px;
  color: var(--text_primary);
  box-sizing: border-box;
  height: 42px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  overflow: hidden;
}

.Select:not(.Select--multiline) .Select__title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Select--not-selected .Select__title {
  color: var(--field_text_placeholder);
}

.Select--align-right .Select__title {
  text-align: right;
}

.Select--align-center .Select__title {
  text-align: center;
}

.Select--multiline .Select__title {
  height: auto;
  min-height: 42px;
}

.Select--sizeY--compact .Select__container {
  height: 34px;
  padding: 7px 11px;
}
