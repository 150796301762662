.CellButton {
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
  margin: 0;
  border: none;
  cursor: pointer;
  width: 100%;
  background: transparent;
  text-align: left;
  padding-top: 0;
  padding-bottom: 0;
}

.CellButton[disabled] {
  opacity: .4;
}

.CellButton--centered {
  justify-content: center;
  text-align: center;
}

.CellButton--centered .SimpleCell__main {
  flex-grow: initial;
  max-width: initial;
}

.CellButton--centered > .Icon {
  padding-right: 8px;
}

.CellButton > .Avatar {
  color: var(--button_muted_background);
}

.CellButton--primary,
.CellButton--primary > .Icon,
.CellButton--primary > .Avatar .Icon {
  color: var(--accent);
}

.CellButton--danger,
.CellButton--danger > .Icon,
.CellButton--danger > .Avatar .Icon {
  color: var(--destructive);
}
